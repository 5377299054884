<!--订单分配设置-->
<template>
  <div style="padding: 20px;">
    <div class="title">
      {{ $route.meta.title }}
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <div>
        <el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="openEdit(null)" v-auths="[`${$config.uniquePrefix}operate:orderAllocationSettings:save`]">
          <i class="el-icon-tianjia" />
          <span style="vertical-align: middle">添加</span>
        </el-button>
      </div>

      <div style="margin-top: 20px;">
        <div style="border: 1px solid #eeeeee;border-radius: 6px;padding: 20px;margin-bottom: 10px;" v-for="(item,index) in list" :key="index">
          <el-row :gutter="15" style="display: flex;align-items: center;">
            <el-col :span="12" style="display: flex;align-items: center;">
              <span style="font-size: 20px;font-weight: 700;color: #050505;">{{item.items}}</span>
              <div class="yangshi">
                <el-input
                  style="width: 180px;margin-left: 60px;"
                  placeholder="请输入"
                  v-model="item.a"
                  clearable>
                </el-input>
                <el-input
                  style="width: 180px;margin-left: 20px;"
                  placeholder="请输入"
                  v-model="item.b"
                  clearable>
                </el-input>
              </div>
            </el-col>
            <el-col :span="12" style="display: flex;justify-content: flex-end;">
              <span style="font-size: 16px;font-weight: 400;color: #ff9b05;cursor: pointer;" @click="openEdit(item)" v-auths="[`${$config.uniquePrefix}operate:orderAllocationSettings:put`]">修改</span>
            </el-col>
          </el-row>
        </div>
        <div v-if="list.length == 0">
          <el-empty description="数据为空"></el-empty>
        </div>
      </div>

    </el-card>

    <!--订单分配弹窗组件-->
    <orderAllocation-edit v-if="showEdit === true" :data="current" :visible.sync="showEdit" @done="getList"/>

  </div>
</template>

<script>
  // 引入订单分配弹窗组件
  import OrderAllocationEdit from './components/orderAllocation-edit.vue'
  import {getorder_apportion} from "@/api/operate";
  export default {
    components: {OrderAllocationEdit},
    data(){
      return{
        //订单分配
        current:null,
        showEdit:false,

        list:[
          // {
          //   title:'拖车',
          //   a:'部门A（%）',
          //   b:'部门B（%）'
          // },
          // {
          //   title:'搭电',
          //   a:'部门A（%）',
          //   b:'部门B（%）'
          // },
          // {
          //   title:'换胎',
          //   a:'部门A（%）',
          //   b:'部门B（%）'
          // },
          // {
          //   title:'困境',
          //   a:'部门A（%）',
          //   b:'部门B（%）'
          // },
        ],


      }
    },

    mounted() {
      this.getList();
      localStorage.removeItem('AllcheckedNodes');
    },

    methods:{
      // 获取列表
      getList(){
        getorder_apportion(this.where).then(async (res) => {
          this.list = res.data;
        }).catch((res) => {
          this.$Message.error(res.msg);
        })
      },

      //显示编辑弹窗
      openEdit(item){
        this.current = item;
        this.showEdit = true;
      },


    }

  }
</script>

<style lang="scss" scoped>

</style>
